import { connect } from 'react-redux';
import { AppState } from '../store';
import { ModelProps, Arf } from '../components/arf/Arf';
import { getActiveArfAccount } from '../selectors/getActiveArfAccount';
import { getActiveArfAccountViolations } from '../selectors/getActiveArfAccountViolations';
import { loadData } from '../util/loadData';
import { arfAccountLoader } from '../util/dataLoaders/arfAccountLoader';
import { arfAccountViolationsLoader } from '../util/dataLoaders/arfAccountViolationsLoader';
import { getActiveArfAccountRemediations } from '../selectors/getActiveArfAccountRemediations';

const mapStateToProps = (state: AppState): ModelProps => ({
  arfAccount: getActiveArfAccount(state),
  violations: getActiveArfAccountViolations(state) ?? [],
  remediations: getActiveArfAccountRemediations(state) ?? [],
});

export const ConnectedArf = loadData(
  [arfAccountLoader, arfAccountViolationsLoader],
  connect(mapStateToProps)(Arf)
);
