import * as React from 'react';
import styled from 'styled-components';
import { ArfAccount, ArfAccountRemediation, ArfAccountViolation } from '../../models';
import { Table } from '../table/Table';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { PolicyItem } from './PolicyItem';
import { InfoTooltip } from '../shared/InfoTooltip';
import { ArfHeader } from './ArfHeader';
import { Failure } from '../shared/Failure';
import { Button } from 'reactstrap';

export interface ModelProps {
  arfAccount: ArfAccount | undefined;
  violations: ArfAccountViolation[];
  remediations: ArfAccountRemediation[];
}

export type Props = ModelProps;

export const Arf: React.FC<Props> = ({ arfAccount, violations, remediations }) => {
  const policies = arfAccount?.policies;

  const [rowState, setRowState] = React.useState(policies?.reduce((state, policy) => {state[policy.id] = false; return state }, {}) ?? []);

  const setRowStateForAll = React.useCallback((open: boolean) => {
    setRowState(policies?.reduce((state, policy) => {state[policy.id] = open; return state }, {}) ?? []);
  }, [setRowState, policies]);

  const anyRowOpen = Object.values(rowState).some((open) => open);

  if (arfAccount === undefined) {
    return <Failure reason="Arf data could not be loaded" />;
  }

  return (
    <OuterDiv>
      <ArfHeader arfAccount={arfAccount} />
      <Table hover={false} size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              ARF Policy Name
            </TableCell>
            <TableCell>
              Open Violations
              <InfoTooltip title="The number of resources found by this policy that would be modified if this policy were set to remediate"/>
            </TableCell>
            <TableCell>
              Remediate
              <InfoTooltip title="When enabled, this toggle gives the policy permission to take action on the resources it finds"/>
            </TableCell>
            <TableCell>
              Status
              <InfoTooltip title="Indicates whether this policy can modify resources in violation or just list them, or indicates the status of switching between the two states"/>
            </TableCell>
            <TableCell sx={{textAlign: 'right'}}>
              <Button id={anyRowOpen ? 'collapse' : 'expand'} onClick={() => setRowStateForAll(anyRowOpen ? false : true)}>{anyRowOpen ? 'Collapse' : 'Expand'} All</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policies?.map((policy) => {
            const matchingDirectives =
              arfAccount?.directives.filter(
                (directive) => directive.policy === policy.id
              ) ?? [];
            const directiveConfig = (matchingDirectives.length > 0
              ? matchingDirectives
              : [undefined])[0];
            if (directiveConfig === undefined) {
              return (
                <TableRow key={policy.id}>
                  <TableCell colSpan={4}>
                    No directives found for policy {policy.name}
                  </TableCell>
                </TableRow>
              );
            }

            return (
              <PolicyItem
                key={policy.id}
                open={rowState[policy.id]}
                setOpen={() => setRowState({...rowState, [policy.id]: !rowState[policy.id]})}
                arfPolicy={policy}
                directives={directiveConfig}
                violations={violations}
                remediations={remediations}
              />
            );
          })}
        </TableBody>
      </Table>
    </OuterDiv>
  );
};

const OuterDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
`;
