import { styled, Switch as MuiSwitch, alpha } from '@mui/material';
import {
  green,
  lightBlue
} from '@mui/material/colors';
import { toast } from 'react-toastify';

export interface Props {
  state: 'off' | 'pending' | 'on' | 'disabled';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const colors = {
  off: green[500],
  pending: lightBlue[500],
  on: green[500],
  disabled: green[500],
};

const disabledToastMessage = 'Self-service toggling of ARF policies is not yet available. Please contact #arf-support on Slack to change the status of policies in your account';

export const Switch: React.FC<Props> = ({ state, onChange, disabled }) => {
  const color = colors[state];

  return <InternalSwitch
    switchColor={color}
    barberPole={state === 'pending'}
    checked={state === 'on' || state === 'pending'}
    onChange={disabled ? () => toast(disabledToastMessage, { type: 'error', autoClose: 10000 }) : onChange}
    disabled={state === 'disabled'}
  />;
};

interface InternalSwitchProps {
  switchColor: string;
  barberPole?: boolean;
}

const InternalSwitch = styled(MuiSwitch, {
  shouldForwardProp: (prop) => prop !== 'switchColor' && prop !== 'barberPole'
})
  <InternalSwitchProps>(({ theme, switchColor, barberPole }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: switchColor,
      '&:hover': {
        backgroundColor: alpha(switchColor, theme.palette.action.hoverOpacity),
      },
      '& + .MuiSwitch-track': barberPole ? {
        backgroundImage: `repeating-linear-gradient(120deg, ${switchColor}, ${switchColor} 7px, #eee 7px, #eee 14px)`,
        backgroundSize: '5000%',
        animation: 'animatedBackground 80s linear infinite',
      } : {},
    },
    '@keyframes animatedBackground': {
      from: {
        backgroundPosition: '0 0'
      },
      to: {
        backgroundPosition: '-100% 0'
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: switchColor,
    },
  }));

// const PendingSwitch = styled(MuiSwitch)(({ theme }) => ({
//   '& .MuiSwitch-switchBase.Mui-checked': {
//     color: lightBlue[500],
//     '&:hover': {
//       backgroundColor: alpha(lightBlue[500], theme.palette.action.hoverOpacity),
//     },
//   },
//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//     backgroundColor: lightBlue[500],
//   },
// }));
