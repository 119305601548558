import { ArrowLeftIcon, CheckIcon, InfoIcon } from '@primer/octicons-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { RequestDetails as CreateIamRoleDetails } from '../../operations/createIamRole';
import { RoleType, IamRole, Role } from '../../models';
import styles from './NewIamRole.module.scss';
import { nextId } from '../../util/nextId';
import { DataFetchStatus } from '../../store/shared/types';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { populateTemplate } from '../../util/populateTemplate';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { toast } from 'react-toastify';

export type OwnProps = RouteComponentProps;

export interface ModelProps {
  iamCapableRole?: Role;
  roleTypes: Map<string, RoleType>;
  creationStatus: DataFetchStatus;
  accountId?: string;
  iamRoles: IamRole[];
}

export interface DispatchProps {
  onSubmit: (auth: OktaAuth, details: CreateIamRoleDetails) => void;
  setIamRoleFormJobId: (jobId: number) => void;
}

export type Props = OwnProps & ModelProps & DispatchProps;

export interface State {
  roleName: string;
  trustedArn: string;
  externalId?: string;
  isMachineIdentity: boolean;
  includeDefaultPolicy: boolean;
  filterText: string;
}

const initialState: State = {
  roleName: '',
  trustedArn: '',
  externalId: undefined,
  isMachineIdentity: false,
  includeDefaultPolicy: false,
  filterText: '',
};

const customRoleType: RoleType = {
  name: 'Custom',
  instanceProfile: false,
  defaultArns: [],
  trustRelationship: {
    Version: '2012-10-17',
    Statement: [
      {
        Action: 'sts:AssumeRole',
        Effect: 'Allow',
        Principal: {},
      },
    ],
  },
  templateFields: [],
};

export const NewIamRole: React.FC<Props> = (props) => {
  const {
    creationStatus,
    setIamRoleFormJobId,
    roleTypes: unmodifiedRoleTypes,
  } = props;

  // Add a special Custom role type to use when modifying the trust policy in the text area
  const roleTypes = React.useMemo(
    () => new Map(unmodifiedRoleTypes).set(customRoleType.name, customRoleType),
    [unmodifiedRoleTypes]
  );

  const { oktaAuth } = useOktaAuth();

  // Equivalent of componentDidMount
  React.useEffect(() => {
    // Always track a fresh job when the component first loads
    if (creationStatus !== DataFetchStatus.DATA_FETCH_UNSTARTED) {
      setIamRoleFormJobId(nextId());
    }
  }, [creationStatus, setIamRoleFormJobId]);

  const [
    showRoleTypeTemplateSection,
    setShowRoleTypeTemplateSection,
  ] = React.useState(false);
  const toggleRoleTypeTemplateSection = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowRoleTypeTemplateSection(!showRoleTypeTemplateSection);
  };

  const [tempRoleName, setTempRoleName] = React.useState(initialState.roleName);
  const handleTempRoleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTempRoleName(e.currentTarget.value);
  };

  const [tempTrustedArn, setTempTrustedArn] = React.useState(
    initialState.trustedArn
  );
  const handleTempTrustedArnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTempTrustedArn(e.currentTarget.value);
  };

  const [tempExternalId, setTempExternalId] = React.useState(
    initialState.externalId
  );
  const handleTempExternalIdChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTempExternalId(e.currentTarget.value);
  };

  const roleTypeNames = React.useMemo(
    () => Array.from(roleTypes.keys()).sort((a, b) => a.localeCompare(b)),
    [roleTypes]
  );
  const [currentRoleType, setRoleType] = React.useState(customRoleType);

  const [isMachineIdentity, setIsMachineIdentity] = React.useState(
    initialState.isMachineIdentity
  );
  const toggleMachineIdentity = () => {
    setIsMachineIdentity(!isMachineIdentity);
  };

  const [templateFields, setTemplateFields] = React.useState(
    {} as Record<string, string>
  );
  const handleTemplateFieldChange = (field) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setTemplateFields({
      ...templateFields,
      [field]: e.currentTarget.value,
    });
  };

  const [filterText, setFilterText] = React.useState(initialState.filterText);
  const handleFilterTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFilterText(e.currentTarget.value);
  };
  const clearFilterText = () => {
    setFilterText('');
  };

  const handleRoleTypeSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const roleType = roleTypes.get(e.currentTarget.innerText);
    if (roleType) {
      setRoleType(roleType);
      const tempTemplateFields = {};
      for (const field of roleType.templateFields) {
        tempTemplateFields[field] = '';
      }
      setTemplateFields(tempTemplateFields);
    }
  };

  const inputRef = React.useRef<HTMLInputElement>(null);
  const setFocus = () => {
    // using setTimeout because the input element is not visible right away
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  // Equivalent of componentWillUpdate
  React.useEffect(() => {
    // When a job succeeds, reset all fields
    if (creationStatus === DataFetchStatus.DATA_FETCH_SUCCEEDED) {
      setTempRoleName(initialState.roleName);
      setTempTrustedArn(initialState.trustedArn);
      setTempExternalId(initialState.externalId);
      setRoleType(customRoleType);
      setIsMachineIdentity(initialState.isMachineIdentity);
    }

    // If a job succeeds or fails, get a new job ID for the next submission
    if (
      creationStatus === DataFetchStatus.DATA_FETCH_SUCCEEDED ||
      creationStatus === DataFetchStatus.DATA_FETCH_FAILED
    ) {
      setIamRoleFormJobId(nextId());
    }
  }, [creationStatus, setIamRoleFormJobId, roleTypeNames, roleTypes]);

  const isPending = creationStatus === DataFetchStatus.DATA_FETCH_PENDING;

  const isCrossAccountRole = currentRoleType.name === 'Cross Account';
  const isInnerAccountRole = currentRoleType.name === 'Inner Account';
  const isServiceRole = !isCrossAccountRole && !isInnerAccountRole;

  const isAnyTemplateFieldEmpty = Object.values(templateFields).reduce(
    (acc, value) => acc || value === '',
    false
  );

  const handleBack = () => {
    props.history.push({
      pathname: props.match.path.replace(/\/new\/?/, ''),
      search: props.location.search,
    });
  };

  const handleNewRole = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const url = `https://ghe.coxautoinc.com/ETS-CloudAutomation/ALKS/issues/new?assignees=&labels=ALKS+Role+Request&template=request--add-role-to-alks.md&title=%5BRole+Request%5D+-+ROLE_NAME`;
    window.open(url);
  };

  const buildTrustPolicyFromRoleType = (roleType: RoleType): string => {
    return populateTemplate(
      JSON.stringify(roleType.trustRelationship, null, 4).replace(
        'ARN_VAR',
        tempTrustedArn
      ),
      { ...templateFields, AWS_ACCOUNT_ID: props.accountId ?? '' }
    );
  };
  const [customTrustPolicy, setCustomTrustPolicy] = React.useState(
    JSON.stringify(currentRoleType.trustRelationship, null, 4)
  );
  const handleTrustPolicyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setCustomTrustPolicy(e.target.value);
    setTemplateFields({});
    setRoleType(customRoleType);
  };

  const trustPolicy =
    currentRoleType.name === 'Custom'
      ? customTrustPolicy
      : buildTrustPolicyFromRoleType(currentRoleType);

  let trustPolicyIsValid = false;

  let jsonTrustPolicy: Record<string, any>;
  try {
    jsonTrustPolicy = JSON.parse(trustPolicy);
    trustPolicyIsValid = true;
  } catch (e) {
    trustPolicyIsValid = false;
    //TODO error handling
  }

  const isReadyToSubmit =
    !isPending &&
    tempRoleName !== '' &&
    !isAnyTemplateFieldEmpty &&
    trustPolicyIsValid;

  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement | HTMLInputElement>
      | React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.iamCapableRole) {
      toast.error('Admin, IAMAdmin, or LabAdmin is needed to create roles');
      return;
    }
    if (isReadyToSubmit) {
      props.onSubmit(oktaAuth, {
        name: tempRoleName,
        isMachineIdentity,
        trustPolicy: jsonTrustPolicy,
      });
    }
  };

  if (!currentRoleType) {
    return <h2>No role types found</h2>;
  }

  if (!props.accountId) {
    return null;
  }

  return (
    <StyledForm onSubmit={handleSubmit} className={styles.newIamRoleForm}>
      <Button
        color="secondary"
        onClick={handleBack}
        className={styles.backButton}
      >
        <ArrowLeftIcon size="small" /> <span>Back</span>
      </Button>{' '}
      <Button
        onClick={handleSubmit}
        color="primary"
        className={styles.saveButton}
        disabled={!isReadyToSubmit}
      >
        {isPending ? (
          <Spinner color="light" size="sm" />
        ) : (
          <CheckIcon size="small" />
        )}{' '}
        <span>Save</span>
      </Button>
      <Input type="submit" onSubmit={handleSubmit} hidden={true} />
      <p />
      <FormGroup row={true}>
        <Label sm={3} for="tempRoleName">
          <b>Role Name:</b>
        </Label>{' '}
        <Col sm={9}>
          <StyledInput
            value={tempRoleName}
            onChange={handleTempRoleNameChange}
            id="tempRoleName"
            className={styles.iamRoleName}
            required={true}
          />
        </Col>
      </FormGroup>
      <FormGroup row={true}>
        <Label sm={3}>
          <b>Role ARN:</b>
        </Label>{' '}
        <Col sm={9} className={styles.iamRoleArn}>
          arn:aws:iam::{props.accountId}:role/acct-managed/
          {tempRoleName}
        </Col>
      </FormGroup>
      <FormGroup check={true} inline={true}>
        <Label>
            <>
              <b>Enable ALKS Access (Machine Identity)</b>{' '}
              <span
                data-tooltip-id="machineIdentityInfo"
                data-tooltip-place="bottom"
                data-tooltip-class-name={styles.tooltip}
              >
                <InfoIcon />
              </span>
              <Tooltip id="machineIdentityInfo">
                <p>
                  Checking this box will tell ALKS to create this role as a
                  machine identity, which means this role will be allowed to
                  manage roles through ALKS for this account.
                </p>
                To enable a role to manage roles for mutiple accounts, create a
                machine identity role in each account in which you would like to
                manage roles and have each of those machine identity roles trust
                a central role.
              </Tooltip>
            <Input
              id="isMachineIdentity"
              type="checkbox"
              checked={isMachineIdentity}
              onChange={toggleMachineIdentity}
              className={styles.iamRoleMachineIdentityCheckbox}
            />
          </>
          </Label>
      </FormGroup>
      <p />
      <FormGroup>
        <Label>
          <b>Trust Policy:</b>
        </Label>{' '}
        <pre>
          <StyledInput
            id="trustPolicy"
            rows={15}
            type="textarea"
            disabled={false}
            value={trustPolicy}
            onChange={handleTrustPolicyChange}
          />
        </pre>
      </FormGroup>
      <p />
      <Button outline={true} onClick={toggleRoleTypeTemplateSection}>
        Use Role Type As Template
      </Button>
      <p />
      <Collapse isOpen={showRoleTypeTemplateSection}>
        <span
          style={{ minHeight: '300px', display: 'inline-block', width: '100%' }}
        >
          <FormGroup>
            <Label>
              <b>Role Type to use as template:</b>{' '}
              <span
                data-tooltip-id="roleTypeInfo"
                data-tooltip-place="bottom"
                data-tooltip-class-name={styles.tooltip}
                // Tooltip definition at the bottom of the StyledForm
              >
                <InfoIcon />
              </span>
            </Label>{' '}
            <InputGroup>
              <RoleTypeValueInput
                className={styles.roleTypeValue}
                readOnly={true}
                value={currentRoleType.name}
              />
              <UncontrolledDropdown>
                <DropdownToggle
                  caret={true}
                  color="primary"
                  onClick={() => {
                    clearFilterText();
                    setFocus();
                  }}
                >
                  Role Type Select
                </DropdownToggle>
                <StyledDropdownMenu>
                  <Button onClick={handleNewRole} color="link" size="sm">
                    Don't see the role you want? Request a new role type!
                  </Button>
                  <StyledInput
                    id="roleTypeSearch"
                    type="text"
                    innerRef={inputRef}
                    onChange={handleFilterTextChange}
                    placeholder="Search for your role..."
                  />
                  <div className={styles.scrollableMenu}>
                    {roleTypeNames.map((roleTypeName) => {
                      if (filterText === '') {
                        return (
                          <StyledDropdownItem
                            key={roleTypeName}
                            onClick={handleRoleTypeSelect}
                          >
                            {roleTypeName}
                          </StyledDropdownItem>
                        );
                      } else if (
                        roleTypeName
                          .toLowerCase()
                          .includes(filterText.toString().toLowerCase())
                      ) {
                        return (
                          <StyledDropdownItem
                            key={roleTypeName}
                            onClick={handleRoleTypeSelect}
                          >
                            {roleTypeName}
                          </StyledDropdownItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </StyledDropdownMenu>
              </UncontrolledDropdown>
            </InputGroup>
          </FormGroup>
          {currentRoleType.instanceProfile && (
            <FormGroup row={true}>
              <Label sm={3}>
                <b>Instance Profile ARN:</b>
              </Label>{' '}
              <Col sm={9} className={styles.iamRoleInstanceProfileArn}>
                arn:aws:iam::{props.accountId}:instance-profile/acct-managed/
                {tempRoleName}
              </Col>
            </FormGroup>
          )}
          {!isServiceRole && (
            <FormGroup row={true}>
              <Label sm={3} for="trustedArn">
                <b>Trusted ARN:</b>{' '}
                <span
                  data-tip={true}
                  data-tooltip-id="trustedArnInfo"
                  data-tooltip-place="bottom"
                  data-tooltip-class-name={styles.tooltip}
                >
                  <InfoIcon />
                </span>
                {isCrossAccountRole && (
                  <Tooltip
                    id="trustedArnInfo"
                  >
                    <p>
                      The Amazon Resource Number (ARN) of the role to trust.
                    </p>
                    <p>
                      Accounts can only trust roles in other accounts with the
                      same security level.
                    </p>
                    <p>
                      For level 1 accounts, Any Prod or NonProd account can
                      trust roles in any other Prod or NonProd account.
                    </p>
                    For level 2 accounts, Any Prod or PreProd account can only
                    trust roles in any other Prod or PreProd account, while
                    NonProd accounts can only trust other NonProd accounts.
                  </Tooltip>
                )}
                {isInnerAccountRole && (
                  <Tooltip
                    id="trustedArnInfo"
                  >
                    The Amazon Resource Number (ARN) of the role to trust
                  </Tooltip>
                )}
              </Label>{' '}
              <Col sm={9}>
                <StyledInput
                  id="trustedArn"
                  value={tempTrustedArn}
                  onChange={handleTempTrustedArnChange}
                  list="iamRoleArns"
                  className={styles.trustedArn}
                  required={true}
                />
                {isInnerAccountRole && (
                  <datalist id="iamRoleArns">
                    {props.iamRoles.map((iamRole) => (
                      <option key={iamRole.arn} value={iamRole.arn} />
                    ))}
                  </datalist>
                )}
              </Col>
            </FormGroup>
          )}
          {isCrossAccountRole && (
            <FormGroup row={true}>
              <Label sm={3} for="externalId">
                <b>External ID:</b>
              </Label>{' '}
              <Col sm={9}>
                <StyledInput
                  id="externalId"
                  value={tempExternalId}
                  onChange={handleTempExternalIdChange}
                  className={styles.externalId}
                />
              </Col>
            </FormGroup>
          )}
          {currentRoleType?.templateFields?.length > 0 &&
            currentRoleType.templateFields.map((field) => (
              <FormGroup row={true}>
                <Label sm={3} for={field}>
                  <b>{field}:</b>
                </Label>{' '}
                <Col sm={9}>
                  <StyledInput
                    id={field}
                    value={templateFields[field]}
                    onChange={handleTemplateFieldChange(field)}
                  />
                </Col>
              </FormGroup>
            ))}
        </span>
      </Collapse>
      <Tooltip id="roleTypeInfo">
        {/* at the bottom to force this to render above other elements */}
        <p>
          The role type that you select will determine which services the
          role will trust.
        </p>
        <p>
          Selecting a role type will update the trust policy shown below
          so that you can inspect the trust policy document before
          creating the role.
        </p>
        <p>
          Once the role is created, the role type cannot be changed,
          although you can always destroy and recreate the role with a
          different role type.
        </p>
        For trusting another IAM role in the same account or another AWS
        account that is managed by ALKS, select either Inner Account or
        Cross Account as the role type.
      </Tooltip>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

const RoleTypeValueInput = styled.input`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &&:disabled,
  &&[readonly] {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &&:disabled,
  &&[readonly] {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;

export default NewIamRole;
